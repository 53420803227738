import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    Image,
    Button,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Checkbox,
    Tbody,
    Td,
    Flex,
  } from "@chakra-ui/react";
  import { rowes } from "../assets/images";
  import ReactPaginate from "react-paginate";
  import moment from "moment";
const TableItem = ({currentItems, handleChange, handleId,type}) => {
    
  return (
    <Box mt="2%">
          <TableContainer
            rounded="md"
        
            border="1px"
            borderColor="gray.300"
          >
            <Table size="sm">
               
                
                <Thead bg="gray.100">
                <Tr>
              
                  <Th>
                    <Text
                      fontSize="12px"
                      fontWeight="600"
                      color="#6B6162"
                      m={2}
                    >
                      Seller Name
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      fontSize="12px"
                      fontWeight="600"
                      color="#6B6162"
                      textAlign="center"
                    >
                      Seller Payout
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      fontSize="12px"
                      fontWeight="600"
                      color="#6B6162"
                      textAlign="center"
                    >
                      Offers Sold
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      fontSize="12px"
                      fontWeight="600"
                      color="#6B6162"
                      textAlign="center"
                    >
                      Offers Redeemed
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize="12px" fontWeight="600" color="#6B6162" textAlign="center">
                      Offer Expired
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize="12px" fontWeight="600" color="#6B6162" textAlign="center">
                      Offers Sold(£)
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize="12px" fontWeight="600" color="#6B6162" textAlign="center">
                      Offers Redeemed (£)
                    </Text>
                  </Th>
                  <Th>
                    <Text>Offers Expired (£)</Text>
                  </Th>
                  <Th>
                    <Text>PHF (Offers Sold)</Text>
                  </Th>
                  <Th>
                    <Text>PHF (Redeemed Offers)</Text>
                  </Th>
                  <Th>
                    <Text>VAT (PHF Redeemed offers)</Text>
                  </Th>
                  <Th>
                    <Text>Refunds</Text>
                  </Th>
                </Tr>
              </Thead>
                    <Tbody bg="white" w="auto" >
                    {currentItems?.map(value => (
                      <Tr key={value._id} mt={4}>
                      
                        <Td>
                          <Flex alignItems="center">
                            <Image
                              borderRadius="full"
                              boxSize="30px"
                              src={value.sellerImg || rowes}
                              ml="10px"
                            />
                            <Text
                              fontSize="14px"
                              fontWeight="600"
                              color="#3B3538"
                              ml="10px"
                              whiteSpace="break-spaces"
                            >
                              {value.sellerName}
                             
                              <br />
                              <Text
                                fontSize="12px"
                                fontWeight="400"
                                color="#3B3538"
                              >
                               {value.sellerAddress}
                              </Text>
                            </Text>
                          </Flex>
                        </Td>
                        <Td w={100}>
                          {value?.sellerPayout }
                        </Td>
                        <Td>
                          <Text fontSize="14px" fontFamily="Commissioner"textAlign="center">
                           {value?.offersSold}
                          </Text>
                        </Td>
                        <Td>
                          <Text fontSize="14px" fontFamily="Commissioner" textAlign="center">
                          {value?.offersRedeemed
                          }
                          </Text>
                        </Td>
                        <Td>
                          <Text fontSize="14px" textAlign="center">
                            {value?.offersExpiredCount}
                          </Text>
                        </Td>
                        <Td>
                          <Text fontSize="14px" fontFamily="Commissioner" textAlign="center">
                          £ {value?.offersSoldAmount}
                          </Text>
                        </Td>
                        <Td>
                          <Text fontSize="14px" fontFamily="Commissioner" textAlign="center">
                          £ {value?.offersRedeemedAmount}
                          </Text>
                        </Td>
                        <Td>
                          <Text fontSize="14px" fontFamily="Commissioner" textAlign="center">
                          {value?.offersExpiredCount} 
                          </Text>
                        </Td>
                        <Td>
                          <Text fontSize="14px" fontFamily="Commissioner" textAlign="center">
                          {value?.PHFOffersSold
                          } 
                          </Text>
                        </Td>
                        <Td>
                          <Text fontSize="14px" fontFamily="Commissioner" textAlign="center">
                          {value?.PHFRedeemedOffers}
                          </Text>
                        </Td>
                        <Td>
                          <Text fontSize="14px" fontFamily="Commissioner" textAlign="center">
                          {value?.VATPHFRedeemedOffers}
                          </Text>
                        </Td>
                        <Td>
                        
                          00
                          
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                   

             
            </Table>
          </TableContainer>
        </Box>
  )
}



const BillingTable = ({itemsPerPage, items, handleChange, handleId,type,totalCount,getBilling}) => {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
  
    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(items);
      setPageCount(Math.ceil(totalCount / itemsPerPage));
    }, [itemOffset, itemsPerPage, items]);

    const handlePageClick = async(event) => {
      let pageNo = event.selected + 1
      const newOffset = (event.selected * itemsPerPage) % totalCount;
      await getBilling(pageNo)
      setItemOffset(newOffset);
    };
  return (
    <>
    <TableItem
      currentItems={currentItems}
      handleChange={handleChange}
      handleId={handleId}
      type={type}
    />
    <Box bg="white" w="full">
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item me-auto"
        previousLinkClassName="page-link ms-auto bg-transparent"
        nextClassName="page-item ms-auto"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination justify-content-center shadow"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </Box>
  </>
  )
}

export default BillingTable

