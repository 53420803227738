import { Box, Center, Image, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { frame, updatedLogo } from "../../assets/icons";
import { FormControl, FormLabel, Button } from "@chakra-ui/react";
import useShowPassword from "../../hooks/useShowPassword";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { userLoginUsingEmail } from "../../api/user";
import { getFromLocalStorage } from "../../helpers";
import { toast } from "react-toastify";
import { onMessageListener, requestForToken } from "../../firebase";

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email is required")
      .email("Enter valid Email"),
    password: yup.string().required("Password is required").min(6).max(16),
  })
  .required();
const Login = () => {
  const { showPassword, renderIcon } = useShowPassword();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getFromLocalStorage("token");
  const [isTokenFound, setTokenFound] = useState(false);
  const [currentToken, setCurrentToken] = useState(null);
  const [notification, setNotification] = useState({ title: "", body: "" });

  requestForToken(setTokenFound, setCurrentToken);
  console.log(currentToken, "isTokenFound ===");

  onMessageListener()
    .then((payload) => {
      // setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      toast.success(payload.data.title);
      // self.registration.showNotification(payload.data.title);
    })
    .catch((err) => console.log("failed: ", err));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (data) => {
    const apiData = { ...data, deviceToken: currentToken };
    setIsLoading(true);
    dispatch(userLoginUsingEmail(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          setIsLoading(false);
          toast.success(res.message);
          navigate("/");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };
  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };
  return (
    <Box w="100vw" h="100vh">
      <Box
        w={["100%", "30%"]}
        position={["relative", "absolute"]}
        top="50%"
        right="50%"
        mx={"auto"}
        transform={`translate(50%, -50%)`}
      >
        <Center>
          <Image src={updatedLogo} />
        </Center>
        <Text textAlign="center" fontSize="14px">
          Admin Dashboard V1.0
        </Text>
        <Box w="full" spacing={8}>
          <FormControl mt="30px" w={["90%", "100%"]} mx="auto">
            <FormLabel color="#6B6162" fontSize="14px">
              Email
            </FormLabel>
            <Input
              type="email"
              placeholder="example@gmail.com"
              boxShadow="lg"
              w={["100%"]}
              variant="outline"
              _focusVisible={{
                outline: "none",
              }}
              {...register("email")}
            />
            <Text my={1} color="red" fontSize="xs">
              {errors.email?.message}
            </Text>
            <FormLabel color="#6B6162" fontSize="14px" mt="20px">
              Password
            </FormLabel>
            <Box position="relative">
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="********"
                boxShadow="lg"
                w={["100%"]}
                {...register("password")}
                my={1}
                _focusVisible={{
                  outline: "none",
                }}
              />
              <Box position="absolute" bottom={"2px"} right={"2px"}>
                {renderIcon()}
              </Box>
            </Box>
            <Text color="red" fontSize="xs">
              {errors.password?.message}
            </Text>
          </FormControl>
          <Text
            onClick={handleForgotPassword}
            my={2}
            fontSize="xs"
            cursor="pointer"
            textAlign="end"
            color="primary"
            w={["90%", "100%"]}
            mx="auto"
          >
            Forgot Password?
          </Text>
          <Box w={["90%", "100%"]} mx="auto">
            <Button
              bg="primary"
              onClick={handleSubmit(handleLogin)}
              mt={6}
              w={["100%"]}
              mx="auto"
              color="white"
              isLoading={isLoading}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
