import {
  Box,
  Text,
  Image,
  AspectRatio,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Select,
  Textarea,
  HStack,
  // VStack,
  Grid,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
// import { maplocation } from "../../assets/images";
import { mappin, sellericon } from "../../assets/icons";
import { useNavigate, useParams } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import {
  changeStatus,
  getSellerById,
  updateSellerInfo,
  uploadMultipleImage,
} from "../../api/seller";
import { useForm } from "react-hook-form";
import { placeholder, user,mapin,marker, } from "../../assets/images";
import profilee from '../../assets/images/profilee.png'
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories, singleFileUpload } from "../../api/category";
import moment from "moment";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { GrFormClose } from "react-icons/gr";
import * as yup from "yup";
import GoogleMapReact from "google-map-react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { FaMapMarkerAlt, FaRegEdit } from "react-icons/fa";
import useShowPassword from "../../hooks/useShowPassword";
import { ViewIcon } from "@chakra-ui/icons";

const schema = yup.object({
  password: yup
    .string()
    .min(8)
    .max(16)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match"),
    subCategory: yup.string()
});

const Marker = ({ }) => (
  <div
    style={{
      color: 'white',
      padding: '10px 15px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      fontSize: "20px",
      transform: 'translate(-50%, -50%)'
    }}
  >
    <img src={marker} alt="marker" />
  </div>
);

const EditSeller = () => {
  const [address, setAddress] = useState("");
  const { seller } = useSelector((state) => state.sellers);
  const { categories } = useSelector((state) => state.offers);
  const [profileImage, setProfileImage] = useState("");
  const [preview, setPreview] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isCommission, setIsCommission] = useState(false);
  const [fileObj, setFileObj] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [value, setValue] = useState(null);
  const [coordinates, setCoordinates] = useState({});
  const [location, setLocation] = useState({
    lat: "",
    lng: "",
  });
  const [zoom, setZoom] = useState(11);
  const [isLoading, setIsLoading] = useState(true);



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const imageRef = useRef();

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const CustomDropdownIndicator = () => {
    return <FaMapMarkerAlt
    onClick={fetchCurrentLocation}
    style={{ color: 'grey', marginRight: '10px',cursor: 'pointer' }} />;
  };
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    if (params?.id) {
      dispatch(getSellerById(params.id))
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }
  }, [dispatch, params]);

  useEffect(() => {
    if (seller) {
      let defaultValues = {};
      defaultValues.businessName = seller?.name?.businessName;
      defaultValues.email = seller?.email;
      defaultValues.subscriptionPlan = seller?.subscriptionPlan;
      defaultValues.amount = seller?.amount;
      defaultValues.phone = seller?.phone?.phoneNumber;
      defaultValues.primaryContact = seller?.name?.contactPerson;
      defaultValues.alternateNumber = seller?.phone?.alternateNumber;
      defaultValues.bio = seller?.bio;
      defaultValues.category = seller?.category?.id || seller?.category;
      defaultValues.subCategory = seller?.subCategory?.id || seller?.subCategory;
      defaultValues.profileImage = seller?.profileImage;
      defaultValues.address = seller?.address?.address;
      defaultValues.commission = seller?.commission;
      setAddress(seller?.address?.address);
      setLocation({
        lat: seller?.address?.latitude,
        lng: seller?.address?.longitude,
      });
      defaultValues.transactionFee = seller?.transactionFee;
      reset({ ...defaultValues });
      setProfileImage(seller?.profileImage);
      setGallery(seller?.gallery);
      setIsSubscribed(["Monthly", "Yearly"].includes(seller?.subscriptionPlan));
      setIsCommission(!!seller?.commission);
    }
    
  }, [seller, reset]);


  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const geocoder = new window.google.maps.Geocoder();
          const latlng = { lat: latitude, lng: longitude };

          geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK' && results[0]) {
              setValue({
                label: results[0].formatted_address,
                value: results[0].place_id,
              });
            } else {
              console.log('Geocoder failed due to: ' + status);
            }
          });
        },
        (error) => {
          console.log('Error fetching the current location: ', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };
  
  const [show, setShow] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleChange = (e) => {
    const img = e.target.files[0];
    if (img) {
      dispatch(singleFileUpload(img))
        .then((res) => setProfileImage(res.data.image))
        .catch((error) => console.log(error));
      setPreview(URL.createObjectURL(img));
    }
  };

  const handleButtonClick = (e) => {
    imageRef.current.click();
  };

  const handleGallery = (e) => {
    const img = e.target.files;
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setGallery(fileArray);
    dispatch(uploadMultipleImage(img))
      .then((res) => {
        if (res.statusCode === 200) {
          const newGallery = res.data.image.map((item) => item.imageUrl);
          setGallery(newGallery);
        }
      })
      .catch((err) => console.log(err));
  };
  
 

  const buttonOnClick = () => {
    navigate("/seller-database");
  };

    useEffect(() => {
    if (params?.id) {
      dispatch(getSellerById(params.id));
    }
  }, [params]);

  const handleSuspendSeller = () => {
    const apiData = {
      status: "suspend",
    };
    dispatch(changeStatus(apiData, params?.id))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          navigate("/seller-database");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const category = categories?.find((item) => item?._id === seller?.category?.id);
  const subCategory = category?.subCategory?.find((item) => item?._id === seller?.subCategory?.id);

  const updateSeller = (data) => {

    
    const apiData = {
      businessName: data.businessName,
      amount: data.amount || 0,
      subscriptionPlan: data.subscriptionPlan || null,
      primaryContact: data.primaryContact,
      email: data.email || "",
      address: value?.label || address,
      city: value?.value?.structured_formatting?.main_text || address,
      phoneNumber: data.phone,
      commission: data.commission || null,
      gallery,
      bio: data.bio,
      password: data.password,
      confirmPassword: data.confirmPassword,
      profileImage,
      transactionFee: data.transactionFee,
      latitude: location.lat,
      longitude: location.lng,
      category: {
        id: category?._id,
        name: category?.mainCategory,
      },
      subCategory: {
        id: subCategory?._id,
        name: subCategory?.name,
      },
    };
    if (apiData.password !== apiData.confirmPassword) {
      alert("Password and confirm password must match.");
    } else {
      dispatch(updateSellerInfo(params?.id, apiData))
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success(res.message);
            navigate("/seller-database");
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => toast.error(err.data.message));
    }
  };

  const handleSubscription = (e) => {
    setIsSubscribed(e.target.checked);
    if (!e.target.checked) {
      reset({ amount: 0 });
    }
  };

  const handleCommission = (e) => {
    setIsCommission(e.target.checked);
    if (!e.target.checked) {
      reset({ transactionFee: 0 });
    }
  };

  const deleteImage = (img) => {
    const newImgURL = gallery.filter((image) => image !== img);
    setGallery(newImgURL);
  };

  useEffect(() => {
    if (value) {
      geocodeByAddress(value.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => setLocation({ lat, lng }));
    }
  }, [value]);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }  
  const defaultProps = {
    center: {
      lat: seller?.address?.latitude,
      lng: seller?.address?.longitude,
    },
    zoom: 16,
  };
  return (
    <Layout>
      <Box w={{lg:"100%",md:"100%"}}flexDirection={["column", "row"]} mx={{lg:"auto",md:"auto"}}>
        <Box
          w="98%"
          mx="auto"
          display="flex"
          justifyContent="space-between"
          flexDirection={["column", "row"]}
        >
          <Box w={["100%", "50%"]}>
            <Text
              fontFamily="Rubik"
              color="#1E0F42"
              fontSize={32}
              fontWeight={600}
              lineHeight="40px"
              letter="-4%"
            >
              Edit Seller
            </Text>
          </Box>

          <Header />
        </Box>

        <Box w={["100%", "100%"]} flexDirection={["column", "row"]} mx={{lg:"auto",md:"auto"}}>
          <Box
            w="95%"
            h="100%"
            mx="auto"
            direction="row"
            justifyContent="space-between"
            flexDirection={["column", "row"]}
          >
            <HStack
              w={["100%", "75%"]}
              justifyContent="space-between"
              alignItems="center"
              flexDirection={["column", "row"]}
            >
              <Box display="flex" alignItems="center">
                <Box position="relative" bg="">
                  <AspectRatio
                    rounded="full"
                    w="120px"
                    h="120px"
                    mt={4}
                    cursor="pointer"
                  >
                    <Image
                      rounded="full"
                      objectFit={"cover"}
                      src={profileImage||profilee}
                      {...register("profileImage")}
                    />
                  </AspectRatio>
                  <Box
                    pt={2}
                    position="absolute"
                    bg="#1E0F42"
                    top={4}
                    right={2}
                    w="36px"
                    h="36px"
                    align="center"
                    rounded="full"
                    color="white"
                    onClick={handleButtonClick}
                  >
                    <AspectRatio w="22px" h="22px" cursor="pointer">
                      <FaRegEdit size="sm" />
                    </AspectRatio>
                  </Box>
                  <Input
                    type="file"
                    display="none"
                    accept="image/png, image/gif, image/jpeg"
                    ref={imageRef}
                    onChange={handleChange}
                  ></Input>
                </Box>
                <Box ml="20px">
                  <Text fontSize="34px" fontWeight="600">
                    {seller?.name?.businessName}
                  </Text>
                  <Text fontSize="23px" color="#72992E" fontWeight="600">
                    {seller?.role}
                  </Text>
                  <Text fontSize="18px" fontWeight="400">
                    Joined in {moment(seller?.createdAt).format("LL")}
                  </Text>
                </Box>
              </Box>

              <Box alignItems="center">
                <Text fontSize="14px" color="#6B6162" fontWeight="400">
                  Quick Actions
                </Text>

                <Button
                  fontSize="14px"
                  bg="#FFA3AC"
                  color="#61002D"
                  w={["100%"]}
                  mt={3}
                  fontWeight="600"
                  onClick={handleSuspendSeller}
                >
                  Suspend Seller
                </Button>
              </Box>
            </HStack>
            <Box w={["100%", "75%"]} my={4}>
              <FormControl
                display="flex"
                direction="row"
                gap={3}
                flexDirection={["column", "row"]}
              >
                <Box w={["100%", "100%"]}>
                  <FormLabel fontSize="14px" color="#6B6162">
                    Business Name
                  </FormLabel>
                  <Input
                    placeholder="Enter name"
                    fontSize="14px"
                    color="#4B4447"
                    bg="white"
                    {...register("businessName")}
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.businessName?.message}
                  </Text>
                </Box>
              </FormControl>

              <HStack
                w="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={3}
                spacing={4}
              >
                <Box w="full">
                  <HStack alignItems="center">
                    <Checkbox
                      onChange={handleSubscription}
                      isChecked={isSubscribed}
                    >
                      <Text fontSize="14px" fontWeight="400" color="#6B6162">
                        Subscription Plan
                      </Text>
                    </Checkbox>
                  </HStack>
                  <Select
                    placeholder="Choose Subscription Plan"
                    fontSize="14px"
                    color="#6B6162"
                    bg="white"
                    mt={2}
                    defaultValue=""
                    disabled={isSubscribed ? true : true}
                    {...register("subscriptionPlan")}
                  >
                    <option value="Yearly">Yearly</option>
                    <option value="Monthly">Monthly</option>
                  </Select>
                </Box>
                <FormControl
                  display="flex"
                  direction="row"
                  justifyContent="space-between"
                  flexDirection={["column", "row"]}
                  w="full"
                >
                  <Box w="full">
                    <FormLabel color="#6B6162" fontSize="14px">
                      Amount
                    </FormLabel>
                    <Input
                      type="number"
                      fontSize="14px"
                      color="#4B4447"
                      bg="white"
                      {...register("amount")}
                      disabled={isSubscribed ? true : true}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.amount?.message}
                    </Text>
                  </Box>
                </FormControl>
              </HStack>
              <Box w="65%" display="flex" justifyContent="space-between" mt={3}>
                <Box>
                  <Checkbox
                    isChecked={isCommission}
                    onChange={handleCommission}
                  >
                    <Text fontSize="14px" fontWeight="400" color="#6B6162">
                      Commision
                    </Text>
                  </Checkbox>
                </Box>
                <Box>
                  <Text fontSize="14px" fontWeight="400" color="#6B6162">
                    Transaction fees
                  </Text>
                </Box>
              </Box>

              <Box
                w="100%"
                display="flex"
                justifyContent="space-between"
                mt={1}
              >
                <Box w="49%">
                  <Input
                    type="number"
                    fontSize="14px"
                    {...register("commission")}
                    color="#6B6162"
                    bg="white"
                    disabled={isCommission ? true : true}
                    // disabled={isCommission}
                  />
                </Box>
                <Box w="49%">
                  <Input
                    fontSize="14px"
                    color="#6B6162"
                    bg="white"
                    disabled={isCommission ? true : true}
                    {...register("transactionFee")}
                  />
                </Box>
              </Box>
              <Box mt="5">
                <FormControl
                  display="flex"
                  direction="row"
                  gap={3}
                  flexDirection={["column", "row"]}
                >
                  <Box w={["100%", "49%"]}>
                    <FormLabel fontSize="14px" color="#6B6162">
                      Primary Email
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      color="#4B4447"
                      bg="white"
                      border="1px"
                      disabled
                      {...register("email")}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.email?.message}
                    </Text>
                  </Box>
                  <Box w={["100%", "49%"]}>
                    <FormLabel color="#6B6162" fontSize="14px" fontWeight="400">
                      Category
                    </FormLabel>
                    <Select
                      placeholder="Select Category"
                      fontSize="14px"
                      color="#6B6162"
                      disabled
                      bg="white"
                      border="1px"
                      {...register("category")}
                    >
                      {categories?.map((value) => (
                        <option key={value._id} value={value._id}>
                          {value.mainCategory}
                        </option>
                      ))}
                    </Select>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.category?.message}
                    </Text>
                  </Box>
                </FormControl>
              </Box>
              <Box>
                    <FormLabel color="#6B6162" fontSize="14px" fontWeight="400">
                     Sub Category
                    </FormLabel>
                    <Select
                      placeholder="Select Sub Category"
                      fontSize="14px"
                      color="#6B6162"
                      disabled
                      bg="white"
                      border="1px"
                      {...register("subCategory")}
                    >
                      {category?.subCategory?.map((value) => (
                        <option key={value._id} value={value._id}>
                          {value.name}
                        </option>
                      ))}
                      {/* <option value="sub-category">Sub-Category</option> */}
                    </Select>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.subCategory?.message}
                    </Text>
                  </Box>
            </Box>
            

            <HStack my={4} w="75%">
              <Box w="full">
                <FormLabel color="#6B6162" fontSize="14px">
                  Primary Contact
                </FormLabel>
                <Input
                  type="text"
                  fontSize="14px"
                  color="#4B4447"
                  bg="white"
                  {...register("primaryContact")}
                />
                <Text my={1} color="red" fontSize="xs">
                  {errors.primaryContact?.message}
                </Text>
              </Box>
              <Box w="full">
                <FormLabel color="#6B6162" fontSize="14px">
                  Primary Phone Number
                </FormLabel>
                <Input
                  type="number"
                  fontSize="14px"
                  color="#4B4447"
                  bg="white"
                  {...register("phone")}
                />
                <Text my={1} color="red" fontSize="xs">
                  {errors.phone?.message}
                </Text>
              </Box>
            </HStack>
            <HStack my={4} w="75%">
              <Box w="full">
                <FormLabel
                  color="#6B6162"
                  fontSize="14px"
                  fontWeight="400"
                  alignSelf="flex-start"
                  mt={["10px", "20px"]}
                  mb="0.5"
                >
                  Password
                </FormLabel>
                <InputGroup>
                  <Input
                    bg="white"
                    shadow="lg"
                    pr="4.5rem"
                    type={showPassword ? "text" : "password"}
                    outline="none"
                    {...register("password")}
                  />

                  <InputRightElement width="4.5rem">
                    <ViewIcon
                      h="1.75rem"
                      size="lg"
                      color="#6B6162"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </ViewIcon>
                  </InputRightElement>
                </InputGroup>
                <Text my={1} color="red" fontSize="xs">
                  {errors.password?.message}
                </Text>
                </Box>
                <Box w="full">
                <FormLabel
                  color="#6B6162"
                  fontSize="14px"
                  fontWeight="400"
                  alignSelf="flex-start"
                  mt={["10px", "20px"]}
                  mb="0.5"
                >
                  Confirm Password
                </FormLabel>

                <InputGroup>
                  <Input
                    bg="white"
                    shadow="lg"
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    {...register("confirmPassword")}
                  />
                  <InputRightElement width="4.5rem">
                    <ViewIcon
                      h="1.75rem"
                      size="lg"
                      color="#6B6162"
                      onClick={handleClick}
                    >
                      {show ? "Hide" : "Show"}
                    </ViewIcon>
                  </InputRightElement>
                </InputGroup>
                <Text my={1} color="red" fontSize="xs">
                  {errors.confirmPassword?.message}
                </Text>
              </Box>
            </HStack>

            <Box
              display="flex"
              justifyContent="space-between"
              w={["100%", "74.5%"]}
              alignItems="center"
              mt="5"
              gap={5}
              flexDirection={["column", "row"]}
            >
              <Box w={["100%"]} mt={4} display="flex">
                <Grid templateColumns="repeat(4, 1fr)" gap={3}>
                  {gallery.length > 0 ? (
                    gallery.map((image) => (
                      <Box
                        key={image.id}
                        w="150px"
                        h="150px"
                        position="relative"
                      >
                        <AspectRatio cursor="pointer" rounded="lg">
                          <Image src={image} rounded="lg" />
                        </AspectRatio>
                        <Box
                          position="absolute"
                          cursor="pointer"
                          bg="white"
                          top={0.5}
                          right={0.5}
                          rounded="full"
                          shadow="lg"
                          onClick={() => deleteImage(image)}
                        >
                          <GrFormClose fontSize={16} />
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <>
                      <Input
                        w="full"
                        bg="white"
                        type="file"
                        multiple={true}
                        accept="image/png, image/gif, image/jpeg ,image/jpg"
                        onChange={handleGallery}
                      ></Input>
                    </>
                  )}
                </Grid>
              </Box>
            </Box>

            <Box w={["98%", "75%"]}>
              <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                About Seller
              </FormLabel>
              <Textarea
                type="text"
                minHeight="300px"
                bg="white"
                {...register("bio")}
                placeholder=" Type Something."
              ></Textarea>
              <Text my={1} color="red" fontSize="xs">
                {errors.bio?.message}
              </Text>
            </Box>

            {/* <Box w={["100%", "75%"]} mt="7" border="1px" borderColor="gray.200">
              <Box w="100%" mx="auto" bg="white" p={2}>
                <FormControl>
                  <FormLabel fontSize="14px" color="#6B6162" mt={1}>
                    Address
                  </FormLabel>
                  <Input
                    placeholder="Add address"
                    fontSize="14px"
                    color="#4B4447"
                    {...register("address")}
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.address?.message}
                  </Text>
                </FormControl>
                <Box
                  position="relative"
                  mt={3}
                  flexDirection={["coliumn", "row"]}
                >
                  <AspectRatio h="269px" w="100%">
                    <Image src={maplocation} />
                  </AspectRatio>
                  <Box position="absolute" right={268} top={120}>
                    <AspectRatio w="25px" h="32px">
                      <Image src={mappin} />
                    </AspectRatio>
                  </Box>
                </Box>
              </Box>
            </Box> */}
            <Box
              w={{ xl: "75%", lg: "75%", md: "75%" }}
              mt="20px"
              border="1px"
              borderColor="gray.200"
              bg="white"
              shadow="lg"
            >
              <Box w="98%" mx="auto" my={4}>
                <GooglePlacesAutocomplete
                  className="form-control border_primary c_sel has-search "
                  apiKey={process.env.REACT_APP_GOOGLE_API}
                  selectProps={{
                    value,
                    onChange: setValue,
                    placeholder: address,
                    components: {
                      DropdownIndicator: (props) => <CustomDropdownIndicator fetchCurrentLocation={fetchCurrentLocation} {...props} />,
                      IndicatorSeparator: () => null // Optional: Remove the default indicator separator
                    }
                  }}
                />
                <Box h={320} my={4}>
                  {/* <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                    <AnyReactComponent
                      lat={location.lat}
                      lng={location.lng}
                      text={<MdLocationOn color="red" />}
                    />
                  </GoogleMapReact> */}
                   <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API }}
                    center={location}
                    zoom={zoom}
                  >
                     <Marker
            lat={location.lat}
            lng={location.lng}
          
          />

                  </GoogleMapReact>
                </Box>
              </Box>
            </Box>
            <Box>
              <Button
                fontSize="14px"
                bg="#FF1A57"
                color="#FFFFFF"
                my={5}
                w={["100%", "75%"]}
                onClick={handleSubmit(updateSeller)}
              >
                Confirm Changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default EditSeller;
