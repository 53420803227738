import api from "./index";


//**************** get all Reports **************** */
export const getAllBillingReport = (pageNo,search,limit) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `/admin/billing/get-billing-report-listing?pageNo=${pageNo}`
        if(search){
            url += `&search=${search}`
        } 
        if(search && limit){
            url += `&search=${search}&limit=${limit}`
        } 
        const res = await api.get(url);
        resolve(res.data);
        
      } catch (err) {
        console.log(err);
      }
    });
  };