import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const useFetch = (url) => {
    const [data,setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(url).then((res)=>{
            setIsLoading(false);
            setData(res);
        })
    },[])
  return {data,isLoading}
}

export default useFetch